<!-- 参展贴士 废弃 2021年06月29日10:42:42-->
<template>
  <div class="tips">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <div class="wCenter">
      <div class="valuesContent">
        <p class="title1" v-if="config.c1&&config.c1.isShow">{{config.c1.title}}</p>
        <div class="content">
          <div class="content1" v-if="config.c2&&config.c2.isShow">
            <p class="title"></p>
            <p class="title">{{config.c2.title}}</p>
            <p class="text" v-for="(item, index) in config.c2.descr.split('\n')" :key="index">{{item}}</p>
          </div>
          <div class="content1 content2" v-if="config.c3&&config.c3.isShow">
            <p class="title"></p>
            <p class="title">{{config.c3.title}}</p>
            <p class="text" v-for="(item, index) in config.c3.descr.split('\n')" :key="index">{{item}}</p>
          </div>
          <div class="content1 content3" v-if="config.c4&&config.c4.isShow">
            <p class="title"></p>
            <p class="title">{{config.c4.title}}</p>
            <p class="text" v-for="(item, index) in config.c4.descr.split('\n')" :key="index">{{item}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
    getPageConfigByCode,
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
  name: '',
  components: {
        TopImg,
    },
    data() {
        return {
            config: {},
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'tips/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.tips {
  .valuesContent {
    margin-top: 0.3rem;
    @media (max-width: 767px) {
      margin-top: 0.3rem;
    }
    .title1 {
      color: #ff1932;
      font-size: 0.3rem;
      margin: 0.6rem 0;
      font-weight: bold;
      @media (max-width: 767px) {
        margin: 0.3rem 0;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      text-align: justify;
      @media (max-width: 767px) {
        display: block;
      }
      .content1 {
        padding: 0.37rem 0.32rem;
        background: #333333;
        width: 6.4rem;
        color: #ffffff;
        margin: 0 0.2rem 0.2rem 0;
        @media (max-width: 767px) {
          width: 100%;
          height: auto;
          margin: 0 0 0.2rem 0;
        }
        .title {
          font-size: 0.24rem;
          margin-bottom: 0.17rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .text {
          font-size: 0.2rem;
		   line-height: 2;
          @media (max-width: 767px) {
            height: auto;
          }
        }
      }
      .content2 {
        background: #ff1932;
        margin-right: 0rem;
      }
      .content3 {
        background: #eeeeee;
        color: #333333;
        margin: 0 0.2rem 0.2rem 0;
        @media (max-width: 767px) {
          margin: 0 0 0.2rem 0;
        }
      }
      .content4 {
        background: #ffb9a0;
        color: #333333;
        margin-right: 0rem;
      }
    }
  }
}
</style>
